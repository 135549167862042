@media screen and (max-width: 1450px) {

    .logo {
        width:25%;
    }

    .logo img {
        width:100%;
    }

    .navbar {
        margin-top:8px;
        margin-right: 0;
    }

}

@media screen and (max-width: 1350px) {
    .navbar li {
        letter-spacing: 0.38em;
    }
}

@media screen and (max-width: 1200px) {
    .navbar li {
        letter-spacing: 0.28em;
    }
}

@media screen and (max-width: 1250px) {

    .page_div{
        max-width: 90%;
    }
    .dobbys, .footer_mid h2{
        max-width: 90%;
        margin-left:0;
    }

    .ml_100 {
        margin-left:40px;
    }

}

@media screen and (max-width: 1100px) {

    .navbar li {
        letter-spacing: 0;
    }

    .hero{
        padding:50px 0;
    }



    .footer_mid{
        width: 100%;
        float:left;
        padding-top:0;
    }

    .footer_mid h2 {
        font-size: 18px;
        text-align: left;
        width: 100%;
        line-height: 25px;
    }

    .swiper_area .title h2{
        font-size:20px
    }

    .iconArea {
        position: relative;
        bottom: -20px;
    }

    .headline_link {
        width: 60px;
        height: 60px;
        font-size: 20px;
    }

    .page img {
        max-width: 100%;
    }
    
    .text_area h2 {
        font-size: 25px;
        margin-bottom: 20px;
    }

    .text_area p {
        font-size: 18px;
    }

    .navbar li {
        font-size: 16px;
        line-height: 20px;
        padding-right: 20px;
    }

}

    @media screen and (max-width: 850px) {  

        .dobbys {
            margin: 0 0 25px 0;
            gap: 15px;
        }

        .navbar li.dot{
            top:-2px;
        }

        .dobby_item {
          
        }

        .hero .w50 {
            width: 100%;
        }

        .sample_pagination .swiper_prev {
            margin-right: 20px;
        }

        .swiper_prev svg {
            width: 16px;
        }
        .swiper_next svg {
            width: 16px;
        }

        .page a {            
            min-height: unset;
        }
        
        .headline_link{
            margin-bottom:25px;
        }

        .page_div{
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        
        .page img {
            margin-bottom: 0px;
        }
        
        .hero{
            padding:30px 0;
        }
    

        .ml_100 {
            margin-left:0
        }
        .swiper_area{
            padding: 50px 0;
        }
        .footer .footer_area span {
            font-size: 16px;
            line-height: 100%;
        }
        .header {
            padding: 30px 0;
        }
        .container {
            padding: 0 10px;
        }
        .page_div {
            max-width: 90%;
        }
        .text_area h2 {
            font-size: 20px;
            margin-bottom: 15px;
        }    
        .text_area p {
            font-size: 14px;
        }

        .logo { width: 100%; text-align: center;}
        .logo img {max-width: 200px;}
        .navbar {
            width: 100%;
            text-align: center;
            margin-top:25px;
        }
    }


    @media (min-width: 375px) and (max-width: 450px) { 
        .navbar li {
            font-size: 12px;
            line-height: 20px;
            padding-right: 12px;
        }        
    }

    
    @media (min-width: 450px) and (max-width: 550px) { 
        .navbar li {
            font-size: 15px;
            line-height: 20px;
            padding-right: 15px;
        }        
    }