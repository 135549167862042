body {
  background-color: #fff;
  font-family: 'Avenir Next LT Pro';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color:inherit;
}


h2,ul,p {
  margin:0;
  padding:0;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Demi.woff2') format('woff2'),
      url('AvenirNextLTPro-Demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Klavika Md';
  src: url('Klavika-Medium.woff2') format('woff2'),
      url('Klavika-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



.container {
  max-width: 1410px;
  margin: 0 auto;
  padding: 0 25px;
}

.header {
  width:100%;
  display: inline-block;
  padding: 50px 0;
}

.logo {
  display: inline-block;
  width: 400px;
}

.sample_pagination .swiper_prev  {
  float: left;
  margin-right: 60px;
}
.sample_pagination .swiper_next {
  float: right;
}

.sample_pagination {
  float: right;
  margin-top: -100px;
}

.logo img {
  max-width: 300px;
  width: 100%;
}

.navbar {  
  float: right;
  margin-top: 15px;
  margin-right: -0.48em
}

.navbar li {
  font-size: 22px;
  line-height: 22px;
  display:inline-block;
  padding-right: 20px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Klavika Md';
  text-align: right;
  letter-spacing: 0.48em;
}

.navbar li:last-child{
  padding-right: 0;
}

.navbar li.dot {
  position: relative;
  top: -5px;
}

.hero {
  flex-direction: column;
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  background: rgba(216, 215, 211, 0.25);
  display: flex;
  padding: 100px 0;
}
.hero .w50 {
  float: left;
  width: 50%;
  max-width: 540px;
}
.page_div {
  max-width: 1090px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
.page a {
  height: 600px;
  float: left;
}
.page img {
  width: 100%;
  float: left;
  height: 100%;
  object-fit: cover;
}
.ml_100 {
  margin-left:100px;
}
.text_area {
  display: inline-block;
  width: 100%;
}
.text_area h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 140%;
  width: 100%;
}
.text_area p {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
}

.iconArea {
  display: inline-block;
  margin-top: 20px;
}

body .swiper-wrapper {

}

body .swiper-slide {

  -webkit-flex-shrink: 0;
  
  -ms-flex: 0 0 auto;
  
  flex-shrink: 0;
  
  width: 100%;
  
  max-width: 150px;
  
  height: 100%;
  
  position: relative;
  
  text-align: center;
  
  }

.swiper_area .title {
  display: inline-block;
    width: 100%;
    margin-bottom: 50px;
}
.swiper_area .title h2 {
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 140%;
  color:#000000;
}

.swiper_area {
  padding: 40px 0;
  display: inline-block;
  width: 100%;
}
.swiper_area .images{
  width: 100%;
  display:inline-block;
  position: relative;
}
.swiper_area .images .images_item {
  display:inline-block;
}
.swiper_area .images .images_item img {
  max-width: 100%;
}

.footer {
  width: 100%;
  display: inline-block;
  border-top: 1px solid #000000;
}
.footer .footer_area {
  width: 250px;
  display: inline-block;
  padding: 50px 0;
}
.footer .footer_area span {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  width: 100%;
  display: inline-block;
}

.footer_mid {
  float: right;
  padding-top: 50px;
}
.footer_mid h2 {
  text-align: left;
  letter-spacing: 0.46em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  font-family: 'Klavika Md';
  max-width: 1090px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.dobbys {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 1090px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.dobby {
  max-width: 100%
}

body .swiper-button-prev {
  float: right;
  top: 0;
  display: inline-block;
  margin-top: 0;
  width: 30px;
  right: 33px;
  left: unset;
}

body .swiper-button-next {
  float: right;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
  margin-top: 0;
  width: 30px;
  left: unset;
}

.headline_link{
  width: 140px;
    height: 140px;
    background-color: #000;
    display: flex;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
}

.kapsar * {
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f2f2f2 38%,
    #f2f2f2 40%,
    #e5e5e5 48%
  );
  background-size: 300%;
  background-position: 100% 0;
  animation: anim 1.5s infinite;
}
@keyframes anim {
  to {
    background-position: -100% 0;
  }
}